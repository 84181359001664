<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form @reset="resetForm">
                <div class="row">
                  <b-form-group label="Nombre" class="col-md-6">
                    <b-form-input
                      v-model="form.name"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Descripción" class="col-md-6">
                    <b-form-input
                      v-model="form.description"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Und. medida" class="col-md-6">
                    <multiselect
                      v-model="form.unit_type"
                      track-by="id"
                      label="name"
                      placeholder="Selecciona und medida"
                      :options="unitTypes"
                      :searchable="true"
                      :allow-empty="true"
                    >
                    </multiselect>
                  </b-form-group>

                  <b-form-group label="Tipo de afect. IGV" class="col-md-6">
                    <multiselect
                      v-model="form.igv_type"
                      track-by="id"
                      label="name"
                      placeholder="Seleccione tipo afect. IGV"
                      :options="igvTypes"
                      :searchable="true"
                      :allow-empty="true"
                    >
                    </multiselect>
                  </b-form-group>

                  <b-form-group label="Precio unitario" class="col-md-6">
                    <b-form-input
                      v-model="form.sale_unit_price"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Cód. barras" class="col-md-6">
                    <b-form-input
                      v-model="form.barcode"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Cód. proveedor" class="col-md-6">
                    <b-form-input
                      v-model="form.provider_product_id"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Cód. fabricante" class="col-md-6">
                    <b-form-input
                      v-model="form.manufacturer_product_id"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Categoría" class="col-md-6">
                    <multiselect
                      v-model="form.category"
                      track-by="id"
                      label="name"
                      placeholder="Selecciona una Categoría"
                      :options="categories"
                      :searchable="true"
                      :allow-empty="true"
                    >
                    </multiselect>
                  </b-form-group>

                  <b-form-group label="Marca" class="col-md-6">
                    <multiselect
                      v-model="form.brand"
                      track-by="id"
                      label="name"
                      placeholder="Selecciona una marca"
                      :options="brands"
                      :searchable="true"
                      :allow-empty="true"
                    >
                    </multiselect>
                  </b-form-group>

                  <b-form-group label="Imagen del producto" class="col-md-6">
                    <b-card
                      :img-src="form.image"
                      img-alt="Image"
                      img-top
                      tag="article"
                      style="max-width: 20rem"
                      class="mb-2"
                    >
                      <b-card-text>
                        <input
                          class="form-control form-control-lg"
                          ref="fileInput"
                          type="file"
                          id="formFileLg"
                          @input="selectImgFile"
                        />
                        Escoja una imagen (será subida al servidor en formato
                        686x386)
                      </b-card-text>
                    </b-card>
                  </b-form-group>

                  <!--
                  <b-form-group label="Subir imagen" class="col-md-6">
                    <b-form-file
                      v-model="image"
                      :state="Boolean(image)"
                      @input="imageInput"
                      placeholder="Elige o arrastra una imagen..."
                      drop-placeholder="Arrastra una imagen aquí..."
                    ></b-form-file>
                  </b-form-group>
                  <b-form-group label="Vista previa imagen" class="col-md-6">
                    <b-img
                      :key="form.imageKey"
                      :src="form.image"
                      fluid
                      alt="Fluid imagen"
                    ></b-img>
                  </b-form-group>
                  -->
                </div>
                <b-button variant="primary" class="mr-2" @click="submit"
                  >Guardar
                </b-button>
                <b-button type="reset" variant="danger" v-if="isEdit === false"
                  >Limpiar
                </b-button>
              </b-form>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Utils from "@/core/utils/utils";

export default {
  name: "ProductCreate",
  data() {
    return {
      action: "Crear",
      brands: [],
      categories: [],
      form: { image: "https://picsum.photos/600/300/?image=25" },
      genders: [],
      igvTypes: [],
      unitTypes: [],
      isEdit: false,
    };
  },
  methods: {
    chooseFile() {
      this.$refs.fileInput.click();
    },
    selectImgFile() {
      let fileInput = this.$refs.fileInput;
      let imgFile = fileInput.files;

      if (imgFile && imgFile[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.form.image = e.target.result;
        };
        reader.readAsDataURL(imgFile[0]);
        this.$emit("fileInput", imgFile[0]);

        const fd = new FormData();
        fd.append("file", imgFile[0]);
        ApiService.post("core/upload/", fd).then((response) => {
          this.form.image = response.data.file;
        });
      }
    },

    submit() {
      const form = {
        ...this.form,
        unit_type: Utils.isNotNullOrEmpty(this.form.unit_type)
          ? this.form.unit_type.id
          : null,
        igv_type: Utils.isNotNullOrEmpty(this.form.igv_type)
          ? this.form.igv_type.id
          : null,
        category: Utils.isNotNullOrEmpty(this.form.category)
          ? this.form.category.id
          : null,
        brand: Utils.isNotNullOrEmpty(this.form.brand)
          ? this.form.brand.id
          : null,
      };
      const method = this.isEdit === true ? ApiService.put : ApiService.post;
      const url =
        this.isEdit === true
          ? `core/item/${this.$route.params.id}/`
          : "core/item/";
      method(url, form).then(() => {
        if (this.isEdit === false) {
          this.resetForm();
        }
      });
    },
    loadForm() {
      let urls = [
        ApiService.get("constants", "?name=IGV_TYPES"),
        ApiService.get("constants", "?name=UNIT_TYPES"),
        ApiService.get("core/category", "?page_size=10000"),
        ApiService.get("core/brand", "?page_size=10000"),
      ];
      if (this.$route.params.id !== undefined) {
        urls.push(ApiService.get(`core/item/${this.$route.params.id}`));
        this.action = "Editar";
        this.isEdit = true;
      }
      Promise.all(urls).then((responses) => {
        this.igvTypes = responses[0].data;
        this.unitTypes = responses[1].data;
        this.categories = responses[2].data.results;
        this.brands = responses[3].data.results;
        this.form = this.isEdit === true ? responses[4].data : { ...this.form };

        this.form = {
          ...this.form,
          brand: this.brands.find((x) => x.id === this.form.brand),
          category: this.categories.find((x) => x.id === this.form.category),
          unit_type: this.unitTypes.find((x) => x.id === this.form.unit_type),
          igv_type: this.igvTypes.find((x) => x.id === this.form.igv_type),
        };

        // ONEROSA default
        if (this.isEdit === false) {
          this.form.igv_type = this.igvTypes.find((x) => x.id === "10");
          this.form.unit_type = this.unitTypes.find((x) => x.id === "NIU");
        }
      });
    },
    resetForm() {
      this.form = {};
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Productos", route: `/core/product` },
      { title: `${this.action} producto` },
    ]);
    this.loadForm();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
